/* CategoryResults.css */

/* Container Styling */
.category-results {
    margin-bottom: 40px;
  }
  
  .category-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #343a40;
    margin-bottom: 15px;
    border-bottom: 2px solid #28a745;
    display: inline-block;
    padding-bottom: 5px;
  }
  
  /* Horizontal Scroll Container */
  .horizontal-scroll-container {
    display: flex;
    overflow-x: auto;
    gap: 15px;
    padding: 10px 0;
    scrollbar-width: thin;
    scrollbar-color: #28a745 #f8f9fa;
    scroll-behavior: smooth;
  }
  
  .horizontal-scroll-container::-webkit-scrollbar {
    height: 8px;
  }
  
  .horizontal-scroll-container::-webkit-scrollbar-thumb {
    background-color: #28a745; /* Green accent color */
    border-radius: 4px;
  }
  
  .horizontal-scroll-container::-webkit-scrollbar-track {
    background: #f8f9fa; /* Light background for scrollbar track */
  }
  
  /* Horizontal Scroll Items */
  .horizontal-scroll-item {
    flex: 0 0 auto;
    width: 250px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .horizontal-scroll-item:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  /* No Results Message */
  .no-results-message {
    font-size: 1rem;
    color: #6c757d;
    text-align: center;
    margin: 20px 0;
  }
  