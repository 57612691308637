/* InstanceCard.css */

/* Link Styling */
.instance-card-link {
    text-decoration: none;
    color: inherit;
  }
  
  /* Card Styling */
  .instance-card {
    width: 100%; /* Maintains flexibility while adapting to its container */
    min-height: 100%; /* Ensures the card stretches vertically to its content */
    max-width: 300px; /* Restricts maximum width for uniformity */
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    margin-bottom: 20px;
  }
  
  .instance-card:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  
  /* Image Styling */
  .instance-card-img {
    height: 300px; /* Keeps the same height for all images */
    object-fit: cover; /* Ensures images maintain aspect ratio without distortion */
  }
  
  /* Title Styling */
  .instance-card-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #343a40;
    margin-bottom: 10px;
  }
  
  /* Text Styling */
  .instance-card-text {
    font-size: 1rem;
    color: #6c757d;
    margin-bottom: 5px;
  }
  
  /* Highlight Styling (if used) */
  .instance-card-text strong {
    color: #28a745; /* Green accent for labels */
  }
  
  /* Responsive Design */
  @media (max-width: 576px) {
    .instance-card {
      max-width: 100%; /* Expands to full width on smaller screens */
    }
  
    .instance-card-img {
      height: 300px; /* Adjusts image height for smaller screens */
      object-fit: cover;
    }
  }
  