/* NavigationBar.css */
.custom-navbar {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid #28a745; /* Subtle green border */
}

.navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffffff;
    padding-left: 20px;
    transition: color 0.3s ease;
}

.navbar-brand:hover {
    color: #28a745; /* Green accent on hover */
}

.nav-link {
    color: #dddddd;
    font-size: 1rem;
    padding: 10px 15px;
    transition: color 0.3s ease, border-bottom 0.3s ease;
}

.nav-link:hover {
    color: #ffffff;
    border-bottom: 2px solid #28a745; /* Green underline on hover */
}

.nav-link.active {
    color: #ffffff;
    font-weight: bold;
    border-bottom: 2px solid #28a745; /* Green underline for active link */
}
