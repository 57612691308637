/* ModelList.css */

/* Container */
.model-list-container {
    margin-bottom: 40px;
    justify-content: center;
  }
  
  /* Title and Description */
  .model-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .model-description {
    font-size: 1.1rem;
    color: #6c757d;
    margin-bottom: 30px;
  }
  
  /* Image */
  .model-image {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  /* Search Bar */
  .search-bar-form {
    max-width: 600px;
    margin: auto;
  }
  
  .search-bar {
    border-radius: 25px;
    padding: 10px 20px;
    border: 1px solid #ddd;
    transition: box-shadow 0.3s ease;
  }
  
  .search-bar:focus {
    border-color: #28a745;
    box-shadow: 0 0 8px rgba(40, 167, 69, 0.5);
  }
  
  /* Filters */
  .filters {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  /* Loading and No Data */
  .loading-state,
  .no-data {
    font-size: 1.2rem;
    color: #6c757d;
  }
  
  /* Pagination Controls */
  .pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination-controls button {
    margin: 0 5px;
  }
  