/* About.css */

/* Hero Section */
.hero-section {
    background-color: #f8f9fa;
    padding: 50px 0;
    text-align: center;
    margin-bottom: 40px;
    border-bottom: 2px solid #28a745;
  }
  
  .hero-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #343a40;
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
    color: #6c757d;
  }
  
  /* Section Styling */
  .section {
    margin-bottom: 40px;
  }
  
  .section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    border-bottom: 2px solid #28a745;
    padding-bottom: 10px;
    color: #343a40;
  }
  
  .about-container {
    max-width: 900px;
    margin: auto;
  }
  
  /* Team Member Cards */
  .team-section .d-flex {
    display: flex !important;
  }
  
  .team-section .card {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .team-section .card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* API Documentation Link */
  .api-link {
    color: #28a745;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .api-link:hover {
    color: #1e7e34;
    text-decoration: underline;
  }
  