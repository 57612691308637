/* SupportGroupTemplate.css */

/* Container Styling */
.support-group-template-container {
    margin-top: 20px;
  }
  
  /* Card Styling */
  .support-group-card,
  .support-group-details-card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  /* Image Styling */
  .support-group-image {
    object-fit: cover;
  }
  
  /* Title Styling */
  .support-group-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  /* Section Styling */
  .location-section {
    margin-top: 15px;
  }
  
  .google-maps-container {
    margin-top: 10px;
  }
  
  .google-maps-iframe {
    width: 100%;
    height: 200px;
    border: none;
    border-radius: 5px;
  }
  
  /* Related Connections */
  .related-connections .btn-secondary {
    background-color: #6c757d;
  }
  
  /* Error Text */
  .error-text {
    color: red;
    font-weight: bold;
  }
  