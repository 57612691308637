/* CostInfo.css */

/* Container Styling */
.cost-info {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  /* Title Styling */
  .cost-info-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #343a40;
    margin-bottom: 10px;
    border-bottom: 2px solid #28a745;
    padding-bottom: 5px;
  }
  
  /* Value Styling */
  .cost-info-value {
    font-size: 1.1rem;
    color: #6c757d;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  
  /* Cost Symbol Styling */
  .cost-symbol {
    font-size: 1.8rem;
    font-weight: bold;
    color: #28a745;
  }
  
  /* Info Items Styling */
  .cost-info-item {
    font-size: 1rem;
    color: #6c757d;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  /* Icon Styling */
  .cost-info-icon {
    color: #28a745;
    margin-right: 10px;
  }
  