/* ProviderVisualizations.css */

/* Main Container */
.provider-visualizations-container {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Title Styling */
  .main-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  /* Section Titles */
  .section-title {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #343a40;
  }
  
  /* Visualization Section */
  .visualization-section {
    margin-bottom: 50px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Chart Container */
  .chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .main-title {
      font-size: 2rem;
    }
  
    .section-title {
      font-size: 1.5rem;
    }
  
    .visualization-section {
      padding: 15px;
    }
  
    .chart-container {
      padding: 10px;
    }
  }
  