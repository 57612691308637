/* ContactInfo.css */

/* Card Styling */
.contact-info-card {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  /* Header Styling */
  .contact-info-header {
    font-size: 1.2rem;
    font-weight: bold;
    color: #343a40;
    margin-bottom: 10px;
    border-bottom: 2px solid #28a745;
    padding-bottom: 5px;
  }
  
  /* Item Styling */
  .contact-info-item {
    font-size: 1rem;
    color: #6c757d;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  /* Icon Styling */
  .contact-info-icon {
    color: #28a745;
    margin-right: 10px;
  }
  
  /* Conditional Styling for Insurance */
  .accepts-insurance {
    color: #28a745;
    font-weight: bold;
  }
  
  .no-insurance {
    color: #dc3545;
    font-weight: bold;
  }
  