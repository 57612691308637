/* Home.css */

/* Hero Section */
.hero-section {
    background: url('../images/Home_Background.jpg') no-repeat center center / cover;
    color: #ffffff;
    padding: 80px 20px;
    text-align: center;
  }
  
  .hero-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  .hero-button {
    padding: 10px 20px;
    font-size: 1rem;
  }
  
  /* Background Image */
  .background-image-container {
    margin: 30px 0;
  }
  
  .background-image {
    width: 100%;
    height: auto;
    max-height: 500px;
    object-fit: cover;
  }
  
  /* Search Section */
  .search-section {
    margin: 50px 0;
  }
  
  .search-form {
    max-width: 600px;
    margin: auto;
  }
  
  .search-bar {
    border-radius: 25px;
    padding: 10px 20px;
    border: 1px solid #ddd;
    transition: box-shadow 0.3s ease, border-color 0.3s ease;
  }
  
  .search-bar:focus {
    border-color: #28a745;
    box-shadow: 0 0 8px rgba(40, 167, 69, 0.5);
  }
  
  /* Footer Spacer */
  .footer-spacer {
    padding-bottom: 100px;
  }
  