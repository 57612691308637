/* PharmacyInfo.css */

/* Card Styling */
.pharmacy-info-card {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  /* Header Styling */
  .pharmacy-info-header {
    font-size: 1.2rem;
    font-weight: bold;
    color: #343a40;
    margin-bottom: 10px;
    border-bottom: 2px solid #28a745;
    padding-bottom: 5px;
  }
  
  /* Item Styling */
  .pharmacy-info-item {
    font-size: 1rem;
    color: #6c757d;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  /* Icon Styling */
  .pharmacy-info-icon {
    color: #28a745;
    margin-right: 10px;
  }
  
  /* Rating Stars Styling */
  .rating-stars {
    display: flex;
    gap: 5px;
  }
  
  .rating-icon {
    color: #ffc107; /* Gold for stars */
    font-size: 1.2rem;
  }
  
  .half-star {
    color: #ffc107;
    opacity: 0.5; /* Add a unique style for half-stars */
  }
  
  .empty-star {
    color: #e4e5e9; /* Light gray for empty stars */
  }
  
  /* Conditional Styling for Medicaid */
  .accepts-medicaid {
    color: #28a745;
    font-weight: bold;
  }
  
  .no-medicaid {
    color: #dc3545;
    font-weight: bold;
  }
  