/* TeamMemberCard.css */

/* Card Styling */
.team-member-card {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .team-member-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Image Styling */
  .team-member-image {
    height: 300px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  /* Card Body */
  .team-member-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #343a40;
  }
  
  .team-member-about {
    font-size: 1rem;
    line-height: 1.5;
    color: #6c757d;
  }
  
  /* Role and Stats Labels */
  .team-member-role,
  .team-member-stats {
    font-weight: bold;
    color: #28a745; /* Green accent color */
  }
  
  .team-member-role {
    margin-top: 10px;
    display: block;
  }
  