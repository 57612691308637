/* SupportGroupFilter.css */

/* Form Container */
.support-group-filter {
    padding: 20px;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  /* Filter Row */
  .filter-row {
    margin-bottom: 15px;
  }
  
  /* Filter Column */
  .filter-col {
    margin-bottom: 15px;
  }
  
  /* Form Labels */
  .support-group-filter .form-label {
    font-size: 0.9rem;
    font-weight: bold;
    color: #343a40;
    margin-bottom: 5px;
  }
  
  /* Typeahead Input */
  .typeahead-input .rbt-input-main {
    border-radius: 25px;
    padding: 10px;
    border: 1px solid #ddd;
    transition: box-shadow 0.3s ease;
  }
  
  .typeahead-input .rbt-input-main:focus {
    border-color: #28a745;
    box-shadow: 0 0 8px rgba(40, 167, 69, 0.5);
  }
  
  /* Select Input */
  .select-input,
  .multiple-select {
    border-radius: 25px;
    padding: 10px;
    border: 1px solid #ddd;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .select-input:focus,
  .multiple-select:focus {
    border-color: #28a745;
    box-shadow: 0 0 8px rgba(40, 167, 69, 0.5);
  }
  
  /* Responsive Spacing */
  @media (max-width: 768px) {
    .filter-col {
      text-align: center;
    }
  }
  