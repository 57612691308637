/* Critiques.css */

/* Main Container */
.critiques-container {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Main Title */
  .main-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  /* Section Titles */
  .section-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #343a40;
  }
  
  /* Critique Card */
  .critique-card {
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 30px;
  }
  
  /* Critique Card Body */
  .critique-card h4 {
    font-size: 1.5rem;
    margin-top: 20px;
    font-weight: bold;
  }
  
  .critique-card p {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .main-title {
      font-size: 2rem;
    }
  
    .section-title {
      font-size: 1.5rem;
    }
  
    .critique-card h4 {
      font-size: 1.3rem;
    }
  
    .critique-card p {
      font-size: 0.9rem;
    }
  }
  